import React from 'react';
import {classNames} from 'lib/styles';
import {RadioGroup} from '@headlessui/react';
import {PaymentMethodType} from 'lib/graphql/API';

import {CheckCircleIcon} from '@heroicons/react/20/solid';

import cardIcon from 'components/atoms/card.png';
import bankIcon from 'components/atoms/bank.png';
import {getBillerConfig} from 'payble-shared';
import {toPaymentMethodType} from '../../lib/types/DirectDebit';

export type PaymentType = {
  name: string;
  type: PaymentMethodType;
  icon: string;
};

export const PAYMENT_METHODS = [
  {
    id: 'credit-card-method',
    name: 'Credit/Debit Card',
    type: PaymentMethodType.Card,
    icon: cardIcon,
  },
  {
    id: 'au-bank-account-method',
    name: 'Bank Account',
    type: PaymentMethodType.DirectDebit,
    icon: bankIcon,
  },
  {
    id: 'nz-bank-account-method',
    name: 'Bank Account',
    type: PaymentMethodType.NzDirectDebit,
    icon: bankIcon,
  },
];

type PaymentMethodPickerProps = {
  paymentType?: PaymentType;
  setPaymentType: (paymentMethod: PaymentType) => void;
  billerSlug: string;
};

export const PaymentMethodPicker: React.FC<PaymentMethodPickerProps> = ({
  paymentType,
  setPaymentType,
  billerSlug,
}) => {
  const billerConfig = getBillerConfig(billerSlug);

  return (
    <RadioGroup value={paymentType} onChange={setPaymentType}>
      <div className="grid w-full grid-cols-2 gap-4 pb-4 mt-8">
        {PAYMENT_METHODS.filter(({type}) =>
          billerConfig.paymentMethods.includes(toPaymentMethodType(type))
        ).map(method => (
          <RadioGroup.Option
            data-testid={method.id}
            key={method.type.toString()}
            value={method}
            className={({checked, active}) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-blue-500' : '',
                'relative mb-4 bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({checked, active}) => (
              <>
                <div className="flex flex-1">
                  <div className="flex flex-1">
                    <img src={method.icon} className="h-6 mr-2" />
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {method.name}
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-blue-600'
                  )}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
