import {Account, Biller, Contact} from 'lib/graphql/API';
import {possessive} from 'payble-shared';

type GetWelcomeDebbieContent = {
  includeGetStarted: boolean;
  externalIdLabel: string;
  biller?: Biller;
  contact?: Contact;
  account?: Account;
};

export const getWelcomeDebbieContent = ({
  includeGetStarted,
  externalIdLabel,
  biller,
  contact,
  account,
}: GetWelcomeDebbieContent) => {
  let title = 'Welcome';
  let message = '';

  if (biller) {
    message = `I'm happy to introduce you to ${possessive(
      biller.name
    )} new flexible payment experience.`;
  }
  if (includeGetStarted) {
    message += ` Please enter your ${externalIdLabel.toLowerCase()} below to get started`;
  }

  if (
    account &&
    account.amountOwing <= 0 &&
    biller?.slug !== 'moreton-bay' &&
    biller?.slug !== 'wingecarribee-shire-council'
  ) {
    title = 'Great news';
    message = 'It looks like your account is already fully paid.';

    if (contact) {
      title += ` ${contact.givenName}!`;
    }
  } else if (contact) {
    title += ` ${contact.givenName} ${contact.familyName}`;
  }

  if (biller?.slug === 'agl-energy') {
    if (account) {
      message =
        'Just confirming, is this the right account you want to set up automatic payments for?';
    } else {
      message =
        "In just a few taps, you'll be able to set up your automatic payments. Please select your account product and enter your account number";
    }
  }

  return {title, message};
};
