import {getBillerConfig} from '..';

export type AccountTypeInfo = {
  id: 'rates' | 'electricity' | 'gas' | 'water' | 'infringements';
  title: string;
  description: string;
  externalIdLabel: string;
  paymentCategory: 'infringements' | 'notice';
  externalIdNumberformat?: string;
};

export const DEFAULT_ACCOUNT_TYPES_INFO: AccountTypeInfo[] = [
  {
    id: 'rates',
    title: 'Rates',
    description: 'Pay your rates online',
    externalIdLabel: 'Account Number',
    paymentCategory: 'notice',
  },
];
function getDefaultAccountTypeInfo(
  billerSlug: string,
  accountType: string = 'rates'
): AccountTypeInfo[] {
  const billerConfig = getBillerConfig(billerSlug);
  const externalIdLabel = billerConfig.getExternalIdLabel(accountType);

  return [
    {
      ...DEFAULT_ACCOUNT_TYPES_INFO[0],
      externalIdLabel,
    },
  ];
}

export const getAccountTypesInfo = (billerSlug: string): AccountTypeInfo[] => {
  const defaultAccountTypeInfo = getDefaultAccountTypeInfo(billerSlug);

  if (billerSlug === 'agl-energy') {
    return [
      {
        id: 'electricity',
        title: 'Electricity',
        description: 'Select electricity account',
        externalIdLabel: 'Account Number',
        paymentCategory: 'notice',
      },
      {
        id: 'gas',
        title: 'Gas',
        description: 'Select gas account',
        externalIdLabel: 'Account Number',
        paymentCategory: 'notice',
      },
    ];
  }

  if (billerSlug === 'energy-on') {
    return [
      {
        id: 'electricity',
        title: 'Electricity',
        description: 'Select electricity account',
        externalIdLabel: 'Customer Number',
        paymentCategory: 'notice',
      },
      {
        id: 'water',
        title: 'Water',
        description: 'Select water account',
        externalIdLabel: 'Customer Number',
        paymentCategory: 'notice',
      },
    ];
  }

  if (billerSlug === 'city-of-kingston') {
    return [
      {
        id: 'rates',
        title: 'Rates',
        description: 'Pay your rates online',
        externalIdLabel: 'Assesment Number',
        externalIdNumberformat: '######/#',
        paymentCategory: 'notice',
      },
    ];
  }

  if (billerSlug === 'moreton-bay') {
    return [
      {
        id: 'rates',
        title: 'Rates',
        description: 'Pay your rates online',
        externalIdLabel: 'Property number',
        paymentCategory: 'notice',
      },
    ];
  }

  if (billerSlug === 'wingecarribee-shire-council') {
    return [
      {
        id: 'rates',
        title: 'Rates',
        description: 'Pay your rates online',
        externalIdLabel: 'Account Number',
        paymentCategory: 'notice',
      },
      {
        id: 'water',
        title: 'Water rates',
        description: 'Pay your water rates online',
        externalIdLabel: 'Water Account Number',
        paymentCategory: 'notice',
      },
    ];
  }

  if (billerSlug === 'city-of-west-torrens') {
    return [
      {
        id: 'rates',
        title: 'Rates',
        description: 'Pay your rates online',
        externalIdLabel: 'Assessment Number',
        paymentCategory: 'notice',
      },
    ];
  }

  if (billerSlug === 'berrigan-shire-council') {
    return [
      {
        ...defaultAccountTypeInfo[0],
        externalIdNumberformat: '#####',
      },
      {
        id: 'water',
        title: 'Water',
        description: 'Pay your water rates online',
        externalIdLabel: 'Water Assessment Number',
        externalIdNumberformat: '#####',
        paymentCategory: 'notice',
      },
    ];
  }
  if (billerSlug === 'city-of-exampleton') {
    return [
      ...defaultAccountTypeInfo,
      {
        id: 'infringements',
        title: 'Infringements',
        description: 'Pay your fines online',
        externalIdLabel: 'Infringement Number',
        paymentCategory: 'infringements',
      },
    ];
  }

  if (billerSlug === 'city-of-greater-dandenong') {
    return [
      ...defaultAccountTypeInfo,
      {
        id: 'infringements',
        title: 'Infringements',
        description: 'Pay your fines online',
        externalIdLabel: 'Infringement Number',
        paymentCategory: 'infringements',
      },
    ];
  }

  if (billerSlug === 'bega-valley-shire') {
    return [
      {
        id: 'rates',
        title: 'Rates',
        description: 'Pay your rates online',
        externalIdLabel: 'Account Number',
        paymentCategory: 'notice',
      },
      // {
      //   id: 'water',
      //   title: 'Water rates',
      //   description: 'Pay your water rates online',
      //   externalIdLabel: 'Water Account Number',
      //   paymentCategory: 'notice',
      // },
    ];
  }

  return defaultAccountTypeInfo;
};
